<template>
  <div>
    <v-layout class="emb-card mb-6">
      <app-card colClasses="xl12 lg12 md12 xs12 sm12" contentCustomClass="pa-6">
        <tabs-and-table></tabs-and-table>
      </app-card>
    </v-layout>
  </div>
</template>

<script>
import TabsAndTable from "Components/Widgets/TabsAndTable";

export default {
  components: {
    TabsAndTable,
  },
};
</script>